

import React, { useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import ArrowUp from "../../assets/images/arrow-up.png"
import ArrowDown from "../../assets/images/arrow-down.png"
import EnrollmentImg from "../../assets/images/Enrollment_banner.jpeg";
import 'primereact/resources/themes/lara-light-indigo/theme.css';   // theme
import 'primereact/resources/primereact.css';
import { useDispatch, useSelector } from "react-redux";
import {fetchStudent_Enrollment_By_Year,
  fetchStudent_Enrollment_By_Grade,fetchStudent_Enrollment_Report
} from '../../redux/slices/counter';

export default function Enrollment() {
  const dispatch = useDispatch();
    const Student_Enrollment_By_Yeardata = useSelector(state => state.netsales.Student_Enrollment_By_Year);
    const Student_Enrollment_By_Gradedata = useSelector(state => state.netsales.Student_Enrollment_By_Grade);
    const Student_Enrollment_Reportdata = useSelector(state => state.netsales.Student_Enrollment_Report);
    // const At_A_Glance_Top_Tiledata = useSelector(state => state.netsales.At_A_Glance_Top_Tile);
    console.log("Student_Enrollment_Reportdata",Student_Enrollment_Reportdata)
    // console.log("At_A_Glance_Top_Tiledata",At_A_Glance_Top_Tiledata)
    var Student_Enrollment_By_Gradedatasort;
    if(Student_Enrollment_By_Gradedata){
      Student_Enrollment_By_Gradedatasort = Student_Enrollment_By_Gradedata.slice().sort((a, b) => {
        return a.GRADE_SORT_ORDER - b.GRADE_SORT_ORDER
    })
    }


    const[screenWidth, setScreenWidth] = useState(0);
    useEffect(() => {
    const handleResize = () => {
    if (typeof window !== 'undefined') {
        const screenWidth = window.innerWidth;
        setScreenWidth(screenWidth);
        console.log(screenWidth,"setScreenWidth");
        }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
    window.removeEventListener('resize', handleResize);
    };
    }, []);
    useEffect(() => {
      dispatch(fetchStudent_Enrollment_By_Year({
        "elasticQueryName": "Student_Enrollment_By_Year",
        "filters": [
          
        ],
        "dynamicColumns": [],
      }));
      dispatch(fetchStudent_Enrollment_By_Grade({
        "elasticQueryName": "Student_Enrollment_By_Grade",
        "filters": [{
          "columnName": "SCHOOL_YEAR",
          "columnValue": "2023",
          "excludeKeyword": false
        }],
        "dynamicColumns": [],
      }));
      dispatch(fetchStudent_Enrollment_Report({
        "elasticQueryName": "Student_Enrollment_Report",
        "filters": [{
          "columnName": "SCHOOL_YEAR",
          "columnValue": "2023",
          "excludeKeyword": false
        }],
        "dynamicColumns": [],
      }));
      // dispatch(fetchAt_A_Glance_Top_Tile({
      //   "elasticQueryName": "At_A_Glance_Top_Tile",
      //   "filters": [
      //   ],
      //   "dynamicColumns": [],
      // }));
  },[])

  const StudentEnrollmentbyGrade = {
    tooltip: {
      trigger: 'axis',
      backgroundColor: 'rgba(50,50,50,0.7)',
      borderColor: "#333",
      textStyle: {
        fontSize: 12,
        fontWeight: "normal",
        color: '#fff',
      },
      axisPointer: {
        type: 'shadow'
      }

    },
    grid: {
      tooltip: {
        padding: [100, 100, 100, 100],
        backgroundColor: 'rgba(50,50,50,0.7)',
        borderColor: "#333",
        color: '#fff'

      }
    },
    title: {
      text: 'Student Enrollment by Grade',
      left: 10,
      textStyle: {
        fontSize: 12,
        fontWeight: "normal",
        color: '#fff',

      },
    },

    grid: {
      left: '5%',
      right: '5%',
      bottom: '0%',
      top: "20%",
      containLabel: true
    },
    yAxis: [
      {
        type: 'value',
        axisLine: {
          show: false,
          lineStyle: {
            color: '#fff'
          }
        },
        // min: 0,
        // max: 10000,
        // interval: 1000,
        axisLabel: {
          show: false,
          color: '#fff'
        },
        splitLine: {
          show: false,
          lineStyle: {
            show: false,
            type: "dashed"
          },
        },
      },
    ],
    xAxis: {
      type: 'category',
      data: Student_Enrollment_By_Gradedatasort.map(itr => itr.KEY),

      axisTick: {
        show: false
      },
      axisLine: {
        show: false,
        lineStyle: {
          color: '#E0E0E0'
        }
      },
      axisLabel: {
        color: '#fff',
        interval: 0,
        rotate:50,
        fontSize: 10,
      },
    },
    series: [
      {
        // name: 'Revenue 2021',
        type: 'bar',
        stack: 'Profit',
        barWidth: "10px",
        color: '#2c4089',

        data: Student_Enrollment_By_Gradedatasort.map(itr => itr.GROUPBY_METRIC_NUMERATOR),
        emphasis: {
          focus: 'series'
        },
        itemStyle: {
          normal: {
            barBorderRadius: [2, 2, 2, 2],
          }
        }
      },

      // {
      //   name: 'Revenue 2022',
      //   type: 'line',
      //   stack: 'Expenses 2022',
      //   color: '#FFA600',
      //   symbolSize: 0,
      //   // data: [21000, 20000, 21000, 23000, 20000, 23000, 26000,24000,25000],
      //   // lineStyle: {
      //   //   width: 3,
      //   // },
      // },

    ]
  };

  const StudentEnrollmentbyYear = {
    title: {
      text: 'Student Enrollment by Year',
      left: 10,
      textStyle: {
        fontSize: 12,
        fontWeight: "normal",
        color: '#fff',
      },
    },
    color: ['#892c69'],
    tooltip: {
      backgroundColor: 'rgba(50,50,50,0.7)',
      borderColor: "#333",
      textStyle: {
        fontSize: 12,
        fontWeight: "normal",
        color: '#fff',
      },
      trigger: 'axis',
      axisPointer: {
        type: 'line',
        lineStyle: {
          type: "line"
        }
      }
    },
    grid: {
      tooltip: {
        padding: [100, 100, 100, 100],
        backgroundColor: 'rgba(50,50,50,0.7)',
        borderColor: "#333",
        color: '#fff'
      }
    },

    grid: {
      right: '0%',
      bottom: '2%',
       //top: '0%',
      // width: '150%',
      height: '70%',
      left: '0%'
    },

    yAxis: [
      {
        type: 'value',
        show: false,
        axisLine: {
          show: false,
          lineStyle: {
            color: '#E0E0E0'
          }
        },
        axisLabel: {
          show: false,
          color: '#4F4F4F'
        },
        splitLine: {
          lineStyle: {
            show: false,
            type: "dashed"
          },
        },
        min: 0,
        max: 170000,
        interval: 1000,
      },
    ],
    xAxis: {
      type: 'category',
      data: Student_Enrollment_By_Yeardata.map(itr => itr.LABEL),
      position: "bottom",
      offset: -30,
      axisTick: {
        show: false
      },
      axisLine: {
        show: false,
        lineStyle: {
          color: '#E0E0E0'
        }
      },
      axisLabel: {
        inside: true,
        color: '#fff'
      },
      z: 10
    },
    series: [
      {
        data: Student_Enrollment_By_Yeardata.map(itr => itr.VALUE),
        type: 'line',
        areaStyle: {},
        label: {
          show: true,
          position: 'top',
          textShadowBlur: false,
          color: '#fff'
        },

      },
    ]
  };
  // #1d192f9c
  // bg-[#00000080] 
  return (
      <div className="bg-[#081630] text-[#fff] h-[100%]">
        <div className="relative h-[190px]">
          <div className=" h-[180px] ">
            <img src={EnrollmentImg} className="h-[190px] w-full " />
          </div>
          <div className="w-full absolute bottom-0 h-[40px] bg-[#1d192f9c] py-2 px-3">
            <h3 className="text-[16px] text-[#fff]">Enrollment</h3>
          </div>
        </div>
        <div className="mt-5 flex gap-6 items-center px-4">
          <div className="">
            <p className="text-[14px] font-normal leading-3">Total</p>
            <h4 className="text-[20px] font-meduim">{Student_Enrollment_Reportdata[2]?.METRIC_NUMERATOR}</h4>
          </div>
          <div style = {{position:"relative",top:"6px"}}>
            {
              Student_Enrollment_Reportdata[2]?.GROUPBY_VARIANCE>=0?<div className="smallarrowbtn" style = {{fontSize:"12px",fontWeight:550}}>
              {Student_Enrollment_Reportdata[2]?.GROUPBY_VARIANCE?.toFixed(1)}%
                <img src={ArrowUp} className="h-2.5 w-2.5" />
              </div>:
              <div className="smallarrowbtndown" style = {{fontSize:"12px",fontWeight:550}}>
              {Student_Enrollment_Reportdata[2]?.GROUPBY_VARIANCE?.toFixed(1)}%
                <img src={ArrowDown} className="h-2.5 w-2.5" /> 
              </div>
            }
            
          </div>
          <div style = {{fontSize:"12px", position:"relative",right:"15px",top:"7px"}}>Last Year Variance Comparison</div>
        </div>
        <div className="grid grid-cols-2 gap-4 px-4 mt-2">
          <div className="flex-col">
            <p className="text-[12px] font-normal leading-5">New</p>
            <p className="text-[16px] font-medium leading-6">{Student_Enrollment_Reportdata[0]?.METRIC_NUMERATOR}</p>
          </div>
          <div className="flex-col">
            <p className="text-[12px] font-normal leading-5">Loss</p>
            <p className="text-[16px] font-medium leading-6">{Student_Enrollment_Reportdata[1]?.METRIC_NUMERATOR}</p>
            {/* <p className="text-[16px] font-medium leading-6"></p> */}
          </div>
        </div>
        <div className="border-t border-[#ffffff26]">
        <div className="h-[120px] mt-5 mb-1 mx-auto center-align-echarts">
          <ReactEcharts style={{ width:  screenWidth <= 1450 && screenWidth >= 1100  ? 240 : 280 , height: screenWidth <= 1450 && screenWidth >= 1100  ? 110 :120}}  option={StudentEnrollmentbyGrade} />
        </div>

        </div>
        <div className="border-t border-[#ffffff26]">
        <div className="StudentEnrollmentbyYear mb-0">
          <ReactEcharts style={{ width:  screenWidth <= 1450 && screenWidth >= 1100  ? 240 : 280, height: screenWidth <= 1450 && screenWidth >= 1100  ? 110 : 120 }} option={StudentEnrollmentbyYear} />
        </div>
        </div>
      </div>
  )
}