

import React, { useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import 'primereact/resources/themes/lara-light-indigo/theme.css';   // theme
import 'primereact/resources/primereact.css';
import StudentCharacteristics_bannerImg from "../../assets/images/StudentCharacteristics_banner.jpeg"
import { useDispatch, useSelector } from "react-redux";
import { fetchStudent_Characteristics,fetchStudent_Group
} from '../../redux/slices/counter';


export default function StudentCharacteristics() {
  const dispatch = useDispatch();
  const Student_Characteristicsdata = useSelector(state => state.netsales.Student_Characteristics);
  let Student_Groupdata = useSelector(state => state.netsales.Student_Group);

  useEffect(() => {
    dispatch(fetchStudent_Characteristics({
      "elasticQueryName": "Student_Characteristics",
      "filters": [{
        "columnName": "SCHOOL_YEAR",
        "columnValue": ["2023"],
        "excludeKeyword": false
      }],
      "dynamicColumns": [],
    }));
    dispatch(fetchStudent_Group({
      "elasticQueryName": "Student_Group",
      "filters": [{
        "columnName": "SCHOOL_YEAR",
        "columnValue": ["2023"],
        "excludeKeyword": false
      }],
      "dynamicColumns": [],
    }));
  }, []);

  
  const[screenWidth, setScreenWidth] = useState(0);
  useEffect(() => {
  const handleResize = () => {
  if (typeof window !== 'undefined') {
      const screenWidth = window.innerWidth;
      setScreenWidth(screenWidth);
      console.log(screenWidth,"setScreenWidth");
      }
  };
  window.addEventListener('resize', handleResize);
  handleResize();
  return () => {
  window.removeEventListener('resize', handleResize);
  };
  }, []);

  const StudentCharacteristics = {
    tooltip: {
      trigger: 'axis',
      backgroundColor: 'rgba(50,50,50,0.7)',
      borderColor: "#333",
      textStyle: {
        fontSize: 12,
        fontWeight: "normal",
        color: '#fff',
      },
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      left: '5%',
      bottom: '15%',
      top: "0%",
      right: '8%',
      height: '100%',
      containLabel: true
    },

    yAxis: [
      {
        type: 'category',
        data: Student_Characteristicsdata.map(itr => itr.LABEL),
        inverse: true,
        axisLine: {
          show: false,
          lineStyle: {
            color: '#E3E3E3'
          }
        },
        axisLabel: {
          color: '#fff',
          fontSize: '10.5',
          fontWeight: "normal",
          position: 'insideRight',
        },
        axisTick: {
          show: false,
        },
      },
    ],

    xAxis: {
      type: 'value',
      axisLabel: {
        show: false,
      },
      inverse: true,
      splitLine: {
        show: false,
        lineStyle: {
          type: "dashed"
        },
      },
      min: 0,
      max: 15000,
      interval: 10,
    },

    series: [
      {
        label: {
          show: false,
          formatter: "{@VALUE}",
        },
        emphasis: {
          focus: 'series'
        },
        data: Student_Characteristicsdata.map(itr => itr.VALUE),
        type: 'bar',
        barWidth: "80%",
        color: '#892c69',
        itemStyle: {
          normal: {
            barBorderRadius: [3, 3, 3, 3],
            color: '#892c69',
          }
        }

      }
    ]
  };

  const StudentGroup = {
    title: {
      // text: 'Student Group',
      left: 10,
      top: 0,
      textStyle: {
        fontSize: 12,
        fontWeight: "normal",
        color: '#fff',
      },
    },

    // tooltip: {
    //   trigger: 'item',
    //   backgroundColor: 'rgba(50,50,50,0.7)',
    //   borderColor: "#333",
    //   textStyle: {
    //     fontSize: 12,
    //     fontWeight: "normal",
    //     color: '#fff',
    //   },
    //   axisPointer: {
    //     type: 'shadow'
    //   }
    // },
    tooltip: {
      trigger: "item",
      formatter: function(params) {
        return (
          params.name + " : " + params.percent.toFixed(2) + "%"
        );
      },
      confine: true
    },

    grid: {
      left: -50,
      bottom: '0%',
      top: "60%",
      containLabel: true
    },
    legend: {
      orient: 'vertical',
      right: '2%',
      // left: "6%",
      itemWidth: 8.5,
      itemHeight: 6,
      top: 'center',
      textStyle: {
        color: "#fff",
        fontSize: '9',
        fontStyle: 'normal'
      }
    },
    series: [
      {
        // name: 'Access From',
        type: 'pie',
        radius: '70%',
        center: ['30%', '50%'],
        avoidLabelOverlap: true,
        label: {
          show: true,
          position: 'left',
          formatter: '{d}%',
          position: 'inside',
          textStyle: {
            fontSize: '8',
            color: "#fff"
          }
        },
        // emphasis: {
        //   label: {
        //     show: true,
        //     fontSize: 10,
        //     fontWeight: 'bold'
        //   }
        // },
        labelLine: {
          show: false
        },
        color: [
          "#2C7AB8",
          "#6F2E67",
          "#2C4990",
          "#32A5AC",
          "#393469",
          "#359760",
          "#992837",
        ],
        data: [
          {
            value: Student_Groupdata[0]?.VALUE,
            name: Student_Groupdata[0]?.LABEL,
            itemStyle:
            {
              color: '#393469'
            }
          },
          {
            value: Student_Groupdata[1]?.VALUE,
            name: Student_Groupdata[1]?.LABEL,
            itemStyle:
            {
              color: '#32A5AC'
            }
          },
          {
            value: Student_Groupdata[2]?.VALUE,
            name: Student_Groupdata[2]?.LABEL,
            itemStyle:
            {
              color: '#992837'
            }
          },
          {
            value: Student_Groupdata[3]?.VALUE,
            name: Student_Groupdata[3]?.LABEL,
            itemStyle:
            {
              color: '#359760'
            }
          },
          {
            value: Student_Groupdata[4]?.VALUE,
            name: Student_Groupdata[4]?.LABEL,
            itemStyle:
            {
              color: '#2C4990'
            }
          },
          {
            value: Student_Groupdata[5]?.VALUE,
            name: Student_Groupdata[5]?.LABEL,
            itemStyle:
            {
              color: '#402B50'
            }
          },
          {
            value: Student_Groupdata[6]?.VALUE,
            name: Student_Groupdata[6]?.LABEL,
            itemStyle:
            {
              color: '#2C7AB8'
            }
          }
        ]
      }
    ]
  };


  const SchoolDistrictEmployeesnew = {
    tooltip: {
        trigger: 'axis',
        backgroundColor: 'rgba(50,50,50,0.7)',
        borderColor: "#333",
        textStyle: {
            fontSize: 12,
            fontWeight: "normal",
            color: '#fff',
        },
        axisPointer: {
            type: 'shadow'
        },
        confine: true
    },
    grid: {
        left: '6%',
        bottom: '0%',
        top: "7%",
        right: '15%',
        height: 190,
        containLabel: true
    },

    yAxis: [
        {
            type: 'category',
            position: "left",
            inverse: true,
            // data: ['Prekindergarten', 'Kindergarten', 'Elementary', 'Secondary','Ungraded'],
            data: [...new Set(Student_Groupdata?.map(item => item["LABEL"]))],
            axisLine: {
                show: false,
                lineStyle: {
                    color: '#E3E3E3'
                }
            },
            axisLabel: {
                color: '#fff',
                fontSize: '10',
                fontWeight: "normal",
                position: 'insideLeft',
            },
            axisTick: {
                show: false,
            },
        },
    ],

    xAxis: {
        show: false,
        // inverse: false,
        min: 0,
        max: 400,
        interval: 5,
        axisLabel:{
            show:true
        }
    },

    series: [
        {
            label: {
                normal: {
                    show: true,
                    position: "right",
                    fontSize: 10,
                    // padding: 10,
                    "formatter": function (params) {
                        return params.value.VALUE
                    },
                    "textStyle": {
                        "color": "#fff"
                    }
                }
            },
            emphasis: {
                focus: 'series'
            },
            // data: [29.12, 25, 360.09, 295.64,119.47],
            // data: [257, 26.00, 358.85, 297.41,119.40],
            data: Student_Groupdata?.map(item => item["VALUE"]),
            type: 'bar',
            barWidth: "80%",
            color: '#1F46AB',
            itemStyle: {
                normal: {
                    barBorderRadius: [3, 3, 3, 3],
                    color: '#1F46AB',
                }
            }

        }
    ]
};

  return (
    <div className="bg-[#081630] text-[#fff] h-[100%]">
      <div className="relative h-[190px]">
        <div className=" h-[180px] ">
          <img src={StudentCharacteristics_bannerImg} className="h-[190px] w-full " alt="banner-img"/>
        </div>
        <div className="w-full absolute bottom-0 h-[40px] bg-[#1d192f9c] py-2 px-3">
          <h3 className="text-[16px] text-[#fff]">Student Characteristics</h3>
        </div>
      </div>
      <div className="StudentCharacteristics bg-[#081630] pb-2 pt-2">
        <ReactEcharts option={StudentCharacteristics} style={{ width:  screenWidth <= 1450 && screenWidth >= 1100  ? 240 : 280 , height: screenWidth <= 1450 && screenWidth >= 1100  ? 140 :150}} />
      </div>
      <div className="border-t py-5 border-[#ffffff26]  bg-[#081630]">
        <div className="StudetGroup">
          <div className="text-white text-[14px] text-left mx-3">Ethnicity</div>
          {/* <ReactEcharts option={StudentGroup} /> */}
          <ReactEcharts option={SchoolDistrictEmployeesnew} style={{ width:  screenWidth <= 1450 && screenWidth >= 1100  ? 240 : 280 , height: screenWidth <= 1450 && screenWidth >= 1100  ? 200 :210}} />
        </div>
      </div>
    </div>
  )
}