
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import ReactEcharts from "echarts-for-react";
import { Dropdown } from 'primereact/dropdown';
import { Carousel } from 'primereact/carousel';
import { ProductService } from '../service/ProductService';
import 'primeicons/primeicons.css';
import ReactEcharts from "echarts-for-react";
import { Chip } from 'primereact/chip';
import TopNav from "../components/common/topnav.component";
import TreadingarrowDown from '../assets/images/treading-arrow-down.png';
import TreadingarrowUp from '../assets/images/treading-arrow-up.png';
import k12darklogo from '../assets/images/k12-dark-logo.png';
import { useDispatch, useSelector } from "react-redux";
import {
    fetchPerformance_Summary_By_Dimensions, fetchstudent_performance_tile_report, fetchstudent_dimension_table_report, fetchstudent_dimension_report, fetchstudent_dimension_table_report_graduation, fetchstudent_dimension_report_graduation
} from '../redux/slices/studentperformance';
import { useMemo } from 'react';
import StachedBarChart from "../components/stachedbarchart";
import { getPivotChartArray } from "../utils";

export default function StrengthWeakness(props) {

    const dispatch = useDispatch();
    const Performance_Summary_By_Dimensionsdata = useSelector(state => state.studentperformance.Performance_Summary_By_Dimensions);
    const student_performance_tile_reportdata = useSelector(state => state.studentperformance.student_performance_tile_report);
    const student_dimension_table_reportdata = useSelector(state => state.studentperformance.student_dimension_table_report);
    const student_dimension_reportdata = useSelector(state => state.studentperformance.student_dimension_report);
    const student_dimension_table_report_graduationdata = useSelector(state => state.studentperformance.student_dimension_table_report_graduation);
    console.log('student_dimension_table_report_graduationdata: ', student_dimension_table_report_graduationdata);
    const student_dimension_report_graduationdata = useSelector(state => state.studentperformance.student_dimension_report_graduation);
    console.log('student_dimension_report_graduationdata: ', student_dimension_report_graduationdata);

    // console.log("student_performance_tile_reportdata",student_performance_tile_reportdata)

    let student_performance_tile_reportdatanew = JSON.parse(JSON.stringify(student_performance_tile_reportdata))
        ?.filter(element => element["METRIC_GROUP"] != "Student Behavior");
    if (student_performance_tile_reportdatanew) {
        student_performance_tile_reportdatanew = student_performance_tile_reportdatanew.slice().sort((a, b) => {
            return a.METRIC_GROUP_ORDER - b.METRIC_GROUP_ORDER
        })
    }
    console.log(student_performance_tile_reportdatanew, 'student_performance_tile_reportdatanew')

    const [performance, setPerformance] = useState("student_ethnicity");
    sessionStorage.setItem("performancesummary", performance);
    const [page, setPage] = useState(0);
    const options = [
        { name: 'Ethnicity', value: 'student_ethnicity' },
        { name: 'Gender', value: 'student_gender' },
        { name: 'Grade', value: 'student_grade' },
        { name: 'Hispanic', value: 'student_hispanic' },
        { name: 'Economically Disadvantaged', value: 'student_economically_disadvantaged' },
        // { name: 'Language Classification', value: 'student_language_classification' },
        { name: 'ELL', value: 'student_ell' },
        // { name: 'Special Ability', value: 'student_special_ability' }
    ];
    const [selectedmetric, setSelectedmetric] = useState(10097);
    sessionStorage.setItem("selectedmetric", selectedmetric);
    const [selectedmetricname, setSelectedmetricname] = useState("Students with >95% Attendance");
    // console.log("selectedmetric",selectedmetricname)
    useEffect(() => {
        dispatch(fetchstudent_performance_tile_report({
            "elasticQueryName": "student_performance_tile_report",
            "filters": [{
                "columnName": "SCHOOL_YEAR",
                "columnValue": ["2023"],
                "excludeKeyword": false
            }],
            "grad_filters": [{
                "columnName": "SCHOOL_YEAR",
                "columnValue": ["2023"],
                "excludeKeyword": false
            }, {
                "columnName": "MEASURE_TYPE",
                "columnValue": ["All"],
                "excludeKeyword": false
            }, {
                "columnName": "SCHOOL_OFFICIAL_NAME",
                "columnValue": ["DistrictWide"],
                "excludeKeyword": false
            }],
            "dynamicColumns": [],
        }));

        // dispatch(fetchstudent_dimension_table_report({
        //     "elasticQueryName": "student_dimension_table_report",
        //     "filters": [{ "columnName": "metric_id", "columnValue": [selectedmetric], "excludeKeyword": false }],
        //     "dynamicColumns": [{ columnName: "#{dimension}", columnValue: ["student_ethnicity"]}],
        // }));

        // dispatch(fetchstudent_dimension_report({
        //     "elasticQueryName": "student_dimension_report",
        //     "filters": [{ "columnName": "metric_id", "columnValue": [selectedmetric], "excludeKeyword": false }],
        //     "dynamicColumns": [{ columnName: "#{dimension}", columnValue: ["student_ethnicity"]}],
        // }));

    }, [])

    useEffect(() => {
        const handleBeforeUnload = () => {
            window.sessionStorage.removeItem('allfilter');
            window.sessionStorage.removeItem('selectedmetric');
            window.sessionStorage.removeItem('performancesummary');
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);
    var defaultallfilter = JSON.parse(sessionStorage.getItem("allfilter"));
    console.log(defaultallfilter, "defaultallfilter")


    useEffect(() => {
        // const body = {
        //     "elasticQueryName": "",
        //     "filters": [{ "columnName": "metric_id", "columnValue": [selectedmetric], "excludeKeyword": false }],
        //     "dynamicColumns": [{ columnName: "#{dimension}", columnValue: [performance] }],
        //     "pivotConfig": [],

        // };

        let body;
        if (defaultallfilter) {
            body = {
                "elasticQueryName": "",
                "filters": defaultallfilter.filters.concat([{ "columnName": "METRIC_ID", "columnValue": [selectedmetric] }]),
                "dynamicColumns": [{ columnName: "#{dimension}", columnValue: [performance] }],
            };


        } else {
            body = {
                "elasticQueryName": "",
                "filters": [{
                    "columnName": "SCHOOL_YEAR",
                    "columnValue": ["2023"],
                    "excludeKeyword": false
                }, { "columnName": "METRIC_ID", "columnValue": [selectedmetric], "excludeKeyword": false }],
                "dynamicColumns": [{ columnName: "#{dimension}", columnValue: [performance] }],
            };
        }
        handleperformance(body)
    }, [selectedmetric, performance]);

    const handleperformance = (body) => {
        let bodyData;
        if (defaultallfilter) {
            bodyData = {
                "elasticQueryName": "",
                "filters": defaultallfilter.filters?.length === 1 ? defaultallfilter.filters.concat([
                    { "columnName": "METRIC_ID", "columnValue": [selectedmetric] },
                    { "columnName": "SCHOOL_OFFICIAL_NAME", "columnValue": "DistrictWide", "excludeKeyword": false }
                ]) : defaultallfilter.filters.concat([
                    { "columnName": "METRIC_ID", "columnValue": [selectedmetric] }
                ]),
                "dynamicColumns": [{ columnName: "#{dimension}", columnValue: [performance] }],
            };
        } else {
            bodyData = {
                "elasticQueryName": "",
                "filters": [{
                    "columnName": "SCHOOL_YEAR",
                    "columnValue": ["2023"],
                    "excludeKeyword": false
                }, { "columnName": "SCHOOL_OFFICIAL_NAME", "columnValue": "DistrictWide", "excludeKeyword": false },
                { "columnName": "METRIC_ID", "columnValue": [selectedmetric], "excludeKeyword": false }],
                "dynamicColumns": [{ columnName: "#{dimension}", columnValue: [performance] }],
            };
        }


        // dispatch(fetchPerformance_Summary_By_Dimensions(body));
        dispatch(fetchstudent_dimension_table_report(body));
        // dispatch(fetchstudent_dimension_report(body));
        dispatch(fetchstudent_dimension_report({
            "elasticQueryName": "",
            "filters": body?.filters?.filter(item => item.columnName != "SCHOOL_YEAR"),
            "dynamicColumns": body?.dynamicColumns

        }));
        dispatch(fetchstudent_dimension_table_report_graduation(bodyData));
        // dispatch(fetchstudent_dimension_report_graduation({
        //     "elasticQueryName": "",
        //     "filters": body?.filters?.filter(item => item.columnName != "SCHOOL_YEAR"),
        //     "dynamicColumns": body?.dynamicColumns

        // }));
        dispatch(fetchstudent_dimension_report_graduation({
            "elasticQueryName": "",
            "filters": bodyData?.filters?.filter(item => item.columnName != "SCHOOL_YEAR"),
            "dynamicColumns": body?.dynamicColumns

        }));

    }

    var tpivot = (data, groupBy, key, value) => {

        let pivotObj = {};
        let grouped = [];
        data.forEach(a => {
            if (!pivotObj[a[groupBy]]) {

                pivotObj[a[groupBy]] = { [groupBy]: a[groupBy] };
                grouped.push(pivotObj[a[groupBy]]);
            }
            let obj = { [a[key]]: a[value] };
            Object.assign(pivotObj[a[groupBy]], obj, a);
        }, Object.create(null));


        return grouped;
    };
    let finalData;
    let finalDatatile;
    let tableReportData = selectedmetric == 10097 ? student_dimension_table_report_graduationdata : student_dimension_table_reportdata
    console.log("tableReportData", tableReportData)
    if (tableReportData && tableReportData.length) {
        finalData = tpivot(tableReportData, "DIMENSION", "METRIC_NAME", "GROUPBY_DISTRICT_PERFORMANCE");
        //  finalDatatile = tpivot(student_dimension_reportdata, "DIMENSION", "TARGET_TOTAL", "ACTUAL_TOTAL");
        console.log("finalData", finalData)
    }

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 7;
    const items = finalData; // your array of items
    const totalPages = Math.ceil(items?.length / itemsPerPage);
    const handleNextClick = () => {
        setCurrentPage(currentPage === totalPages ? 1 : currentPage + 1);
    };
    const handlePrevClick = () => {
        setCurrentPage(currentPage === 1 ? totalPages : currentPage - 1);
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentItems = items?.slice(startIndex, endIndex);

    console.log("currentItems", currentItems)

    const [products, setProducts] = useState([]);
    const responsiveOptions = [
        {
            breakpoint: '1199px',
            numVisible: 5,
            numScroll: 5
        },
        {
            breakpoint: '991px',
            numVisible: 2,
            numScroll: 2
        },
        {
            breakpoint: '767px',
            numVisible: 1,
            numScroll: 1
        }
    ];

    // useEffect(() => {
    //     ProductService.getProductsSmall().then((data) => setProducts(data.slice(0, 9)));
    // }, []);

    const handleTopTileFilterClick = (tile) => {
        console.log('tile: ', tile);
        setSelectedmetric(tile?.METRIC_ID)
        setSelectedmetricname(tile?.METRIC_NAME)
    }

    const productTemplates = (finalData) => {
        return (
            <div className='cohortbox-carousel pt-0'>
                <div className='product-item'>
                    <div className='legendbox'>
                        <div className='legend-header text-xs'><p>{finalData?.DIMENSION}</p></div>
                        <div className='flex justify-between items-center'>
                            <div className='flex justify-start items-center'>
                                <span className="text-[13px] font-[600] mr-2">{finalData?.GROUPBY_DISTRICT_PERFORMANCE.toFixed(1)}% </span>
                                {
                                    finalData?.LAST_PERIOD_VARIANCE > 0 ? <Chip label={finalData?.LAST_PERIOD_VARIANCE?.toFixed(1) + "%"} icon="pi pi-arrow-up-right" style={{ background: "#1C6130", fontSize: 11, height: 20, color: '#fff' }} /> :
                                        finalData?.LAST_PERIOD_VARIANCE == 0 ? <Chip label={finalData?.LAST_PERIOD_VARIANCE?.toFixed(1) + "%"} icon="pi pi-minus" style={{ background: "#808080", fontSize: 11, height: 20, color: '#fff' }} /> :
                                            <Chip label={finalData?.LAST_PERIOD_VARIANCE?.toFixed(1) + "%"} icon="pi pi-arrow-down-right" style={{ background: "#6A1D25", fontSize: 11, height: 20, color: '#fff' }} />
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const columnsMapping = (tempObj, a, column, columnsRestored) => {
        Object.keys(a).forEach((keys) => {
            if (column.includes(keys)) {
                tempObj[keys + "_" + a.METRIC_BAND_ORDER] = a[keys];
            }
            if (columnsRestored.includes(keys)) {
                tempObj[keys] = a[keys];
            }
        });
    };

    const groupByTimeSeries = (data, row, column, columnsRestored) => {
        let tempObj = {};
        let finalData1 = [];
        let dimensions = [];
        if (data) {
            data.forEach((item) => {
                if (!dimensions.includes(item[row])) {
                    dimensions.push(item[row]);
                }
            });
            dimensions.forEach((m) => {
                let flag = false;
                tempObj = {};
                data.forEach((a, index) => {
                    if (!flag) {
                        tempObj[row] = a[row];
                    }
                    if (m == a[row]) {
                        columnsMapping(tempObj, a, column, columnsRestored);
                        flag = true;
                    }
                });
                if (flag) {
                    finalData1.push(tempObj);
                }
            });
        }

        return finalData1;

    };

    let sudentDimensionFinalData = selectedmetric == 10097 ? student_dimension_report_graduationdata : student_dimension_reportdata
    let student_dimension_reportdatanew = JSON.parse(JSON.stringify(sudentDimensionFinalData));


    student_dimension_reportdatanew.map(itr => {
        if (itr) {
            if (itr["SCHOOL_MONTH"] > 0) {
                itr["overallvalue"] = itr["SCHOOL_YEAR"] + "/" + itr["SCHOOL_MONTH"]
            } else {
                itr["overallvalue"] = itr["SCHOOL_YEAR"]
            }
            itr["TARGET_TOTAL"] = Number(itr["TARGET_TOTAL"])?.toFixed(1)
            itr["ACTUAL_TOTAL"] = Number(itr["ACTUAL_TOTAL"])?.toFixed(1)
        }
    })



    if (performance == "student_ethnicity") {
        student_dimension_reportdatanew?.map(itr => {
            if (itr["DIMENSION"] == "Asian or Pacific Islander") {
                itr["METRIC_BAND_COLOR"] = "#019049";
                itr["METRIC_BAND_ORDER"] = 1
            } else if (itr["DIMENSION"] == "Amer. Indian or Alaskan Native") {
                itr["METRIC_BAND_COLOR"] = "#2DE78B";
                itr["METRIC_BAND_ORDER"] = 2
            } else if (itr["DIMENSION"] == "Hispanic") {
                itr["METRIC_BAND_COLOR"] = "#2DE78B";
                itr["METRIC_BAND_ORDER"] = 3
            }
            else if (itr["DIMENSION"] == "Black, Non-Hispanic") {
                itr["METRIC_BAND_COLOR"] = "#E6DE32";
                itr["METRIC_BAND_ORDER"] = 4
            }
            else if (itr["DIMENSION"] == "Multi-racial") {
                itr["METRIC_BAND_COLOR"] = "#E6DE32";
                itr["METRIC_BAND_ORDER"] = 5
            }
            else if (itr["DIMENSION"] == "White, Non-Hispanic") {
                itr["METRIC_BAND_COLOR"] = "#E6DE32";
                itr["METRIC_BAND_ORDER"] = 6
            }
            else if (itr["DIMENSION"] == "Not Reported") {
                itr["METRIC_BAND_COLOR"] = "#E6DE32";
                itr["METRIC_BAND_ORDER"] = 7
            }
            return itr;
        })
    } else if (performance == "student_gender") {
        student_dimension_reportdatanew?.map(itr => {
            if (itr["DIMENSION"] == "Male") {
                itr["METRIC_BAND_COLOR"] = "#019049";
                itr["METRIC_BAND_ORDER"] = 1
            } else if (itr["DIMENSION"] == "Female") {
                itr["METRIC_BAND_COLOR"] = "#2DE78B";
                itr["METRIC_BAND_ORDER"] = 2
            }
            return itr;
        })
    } else if (performance == "student_homeless") {
        student_dimension_reportdatanew?.map(itr => {
            if (itr["DIMENSION"] == "Not Reported") {
                itr["METRIC_BAND_COLOR"] = "#019049";
                itr["METRIC_BAND_ORDER"] = 1
            } else if (itr["DIMENSION"] == "Yes") {
                itr["METRIC_BAND_COLOR"] = "#2DE78B";
                itr["METRIC_BAND_ORDER"] = 2
            }
            return itr;
        })
    } else if (performance == "student_economically_disadvantaged") {
        student_dimension_reportdatanew?.map(itr => {
            if (itr["DIMENSION"] == "Qualified Free") {
                itr["METRIC_BAND_COLOR"] = "#019049";
                itr["METRIC_BAND_ORDER"] = 1
            } else if (itr["DIMENSION"] == "Not Reported") {
                itr["METRIC_BAND_COLOR"] = "#2DE78B";
                itr["METRIC_BAND_ORDER"] = 2
            }
            return itr;
        })
    } else if (performance == "student_ell") {
        student_dimension_reportdatanew?.map(itr => {
            if (itr["DIMENSION"] == "Not Reported") {
                itr["METRIC_BAND_COLOR"] = "#019049";
                itr["METRIC_BAND_ORDER"] = 1
            } else if (itr["DIMENSION"] == "No") {
                itr["METRIC_BAND_COLOR"] = "#2DE78B";
                itr["METRIC_BAND_ORDER"] = 2
            } else if (itr["DIMENSION"] == "Yes") {
                itr["METRIC_BAND_COLOR"] = "#2DE78B";
                itr["METRIC_BAND_ORDER"] = 3
            }
            return itr;
        })
    } else if (performance == "student_special_ability") {
        student_dimension_reportdatanew?.map(itr => {
            if (itr["DIMENSION"] == "Yes") {
                itr["METRIC_BAND_COLOR"] = "#019049";
                itr["METRIC_BAND_ORDER"] = 1
            } else if (itr["DIMENSION"] == "No") {
                itr["METRIC_BAND_COLOR"] = "#2DE78B";
                itr["METRIC_BAND_ORDER"] = 2
            }
            return itr;
        })
    } else if (performance == "student_grade") {
        student_dimension_reportdatanew?.map(itr => {
            if (itr["DIMENSION"] == "Preschool/Prekindergarten") {
                itr["METRIC_BAND_COLOR"] = "#019049";
                itr["METRIC_BAND_ORDER"] = 1
            } else if (itr["DIMENSION"] == "KinderGarten") {
                itr["METRIC_BAND_COLOR"] = "#2DE78B";
                itr["METRIC_BAND_ORDER"] = 2
            } else if (itr["DIMENSION"] == "First Grade") {
                itr["METRIC_BAND_COLOR"] = "#2DE78B";
                itr["METRIC_BAND_ORDER"] = 3
            } else if (itr["DIMENSION"] == "Second Grade") {
                itr["METRIC_BAND_COLOR"] = "#2DE78B";
                itr["METRIC_BAND_ORDER"] = 4
            } else if (itr["DIMENSION"] == "Third Grade") {
                itr["METRIC_BAND_COLOR"] = "#2DE78B";
                itr["METRIC_BAND_ORDER"] = 5
            } else if (itr["DIMENSION"] == "Fourth Grade") {
                itr["METRIC_BAND_COLOR"] = "#2DE78B";
                itr["METRIC_BAND_ORDER"] = 6
            } else if (itr["DIMENSION"] == "Fifth Grade") {
                itr["METRIC_BAND_COLOR"] = "#2DE78B";
                itr["METRIC_BAND_ORDER"] = 7
            } else if (itr["DIMENSION"] == "Sixth Grade") {
                itr["METRIC_BAND_COLOR"] = "#2DE78B";
                itr["METRIC_BAND_ORDER"] = 8
            } else if (itr["DIMENSION"] == "Seventh Grade") {
                itr["METRIC_BAND_COLOR"] = "#2DE78B";
                itr["METRIC_BAND_ORDER"] = 9
            } else if (itr["DIMENSION"] == "Eighth Grade") {
                itr["METRIC_BAND_COLOR"] = "#2DE78B";
                itr["METRIC_BAND_ORDER"] = 10
            } else if (itr["DIMENSION"] == "Ninth Grade") {
                itr["METRIC_BAND_COLOR"] = "#2DE78B";
                itr["METRIC_BAND_ORDER"] = 11
            } else if (itr["DIMENSION"] == "Tenth Grade") {
                itr["METRIC_BAND_COLOR"] = "#2DE78B";
                itr["METRIC_BAND_ORDER"] = 12
            } else if (itr["DIMENSION"] == "Eleventh Grade") {
                itr["METRIC_BAND_COLOR"] = "#2DE78B";
                itr["METRIC_BAND_ORDER"] = 13
            } else if (itr["DIMENSION"] == "Twelfth Grade") {
                itr["METRIC_BAND_COLOR"] = "#2DE78B";
                itr["METRIC_BAND_ORDER"] = 14
            } else if (itr["DIMENSION"] == "Early Education") {
                itr["METRIC_BAND_COLOR"] = "#2DE78B";
                itr["METRIC_BAND_ORDER"] = 15
            }
            return itr;
        })
    }


    let barChartData = getPivotChartArray(student_dimension_reportdatanew, 'SCHOOL_YEAR', 'CATEGORY_TYPE', 'ACTUAL_TOTAL')
    let lineChartData = getPivotChartArray(student_dimension_reportdatanew, 'SCHOOL_YEAR', 'CATEGORY_TYPE', 'TARGET_TOTAL')

    console.log("barChartData", barChartData)

    const dimensions = [...new Set(student_dimension_reportdatanew.map(item => item.DIMENSION))];
    const years = [...new Set(student_dimension_reportdatanew.map(item => item.SCHOOL_YEAR))];

    let targetData = years.map(year => {
        const entry = student_dimension_reportdatanew.find(item => item.SCHOOL_YEAR === year);
        return {
            SCHOOL_YEAR: year,
            TARGET_TOTAL: entry ? parseFloat(entry.TARGET_TOTAL) : 0
        }

    })


    const combinedData = barChartData.map(absence => {
        const target = targetData.find(t => t.SCHOOL_YEAR.toString() === absence.SCHOOL_YEAR);
        return {
            ...absence,
            TARGET_TOTAL: target ? target.TARGET_TOTAL : null
        };
    });

    let columns = ["ACTUAL_TOTAL"];
    let columnsRestored = [
        "TARGET_TOTAL",
    ];
    let finalData1 = groupByTimeSeries(
        student_dimension_reportdatanew,
        "overallvalue",
        columns,
        columnsRestored
    );
    console.log("finalData1", finalData1)

    let data;
    if (performance == "student_ethnicity") {
        data = [
            {
                name: 'Asian or Pacific Islander',
                type: 'bar',
                stack: 'Asian or Pacific Islander',
                barWidth: "10%",
                color: '#2b79b3',
                animation: false,
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_1
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'Amer. Indian or Alaskan Native',
                type: 'bar',
                stack: 'Amer. Indian or Alaskan Native',
                barWidth: "10%",
                color: '#2b3d82',
                animation: false,
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_2
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'Hispanic',
                type: 'bar',
                stack: 'Hispanic',
                barWidth: "10%",
                color: '#802a64',
                animation: false,
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_3
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'Black, Non-Hispanic',
                type: 'bar',
                stack: 'Black, Non-Hispanic',
                barWidth: "10%",
                color: '#13917c',
                animation: false,
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_4
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'Multi-racial',
                type: 'bar',
                stack: 'Multi-racial',
                barWidth: "10%",
                color: '#cac2f9',
                animation: false,
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_5
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'White, Non-Hispanic',
                type: 'bar',
                stack: 'White, Non-Hispanic',
                barWidth: "10%",
                color: '#2b79b3',
                animation: false,
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_6
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'Not Reported',
                type: 'bar',
                stack: 'Not Reported',
                barWidth: "10%",
                color: '#2b79b3',
                animation: false,
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_7
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'Actual',
                type: 'line',
                stack: 'Actual',
                color: '#fff',
                symbolSize: 10,
                symbol: 'circle',
                animation: false,
                data: finalData1?.map(itr =>
                    itr?.TARGET_TOTAL
                ),
                lineStyle: {
                    width: 3,
                },
            },

        ]
    } else if (performance == "student_gender") {
        data = [
            {
                name: 'Male',
                type: 'bar',
                stack: 'Male',
                barWidth: "10%",
                color: '#2b79b3',
                animation: false,
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_1
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'Female',
                type: 'bar',
                stack: 'Female',
                barWidth: "10%",
                color: '#2b3d82',
                animation: false,
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_2
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'Actual',
                type: 'line',
                stack: 'Actual',
                color: '#fff',
                symbolSize: 10,
                symbol: 'circle',
                animation: false,
                data: finalData1?.map(itr =>
                    itr?.TARGET_TOTAL
                ),
                lineStyle: {
                    width: 3,
                },
            },
        ]
    } else if (performance == "student_homeless") {
        data = [
            {
                name: 'Not Reported',
                type: 'bar',
                stack: 'Not Reported',
                barWidth: "10%",
                color: '#2b79b3',
                animation: false,
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_1
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'Yes',
                type: 'bar',
                stack: 'Yes',
                barWidth: "10%",
                color: '#2b3d82',
                animation: false,
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_2
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'Actual',
                type: 'line',
                stack: 'Actual',
                color: '#fff',
                symbolSize: 10,
                symbol: 'circle',
                animation: false,
                data: finalData1?.map(itr =>
                    itr?.TARGET_TOTAL
                ),
                lineStyle: {
                    width: 3,
                },
            },
        ]
    } else if (performance == "student_economically_disadvantaged") {
        data = [
            {
                name: 'Qualified Free',
                type: 'bar',
                stack: 'Qualified Free',
                barWidth: "10%",
                color: '#2b79b3',
                animation: false,
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_1
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'Not Reported',
                type: 'bar',
                stack: 'Not Reported',
                barWidth: "10%",
                color: '#2b3d82',
                animation: false,
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_2
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'Actual',
                type: 'line',
                stack: 'Actual',
                color: '#fff',
                symbolSize: 10,
                symbol: 'circle',
                data: finalData1?.map(itr =>
                    itr?.TARGET_TOTAL
                ),
                lineStyle: {
                    width: 3,
                },
            },
        ]
    } else if (performance == "student_ell") {
        data = [
            {
                name: 'Not Reported',
                type: 'bar',
                stack: 'Not Reported',
                barWidth: "10%",
                color: '#2b79b3',
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_1
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'No',
                type: 'bar',
                stack: 'No',
                barWidth: "10%",
                color: '#2b3d82',
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_2
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'Yes',
                type: 'bar',
                stack: 'Yes',
                barWidth: "10%",
                color: '#802a64',
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_3
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'Actual',
                type: 'line',
                stack: 'Actual',
                color: '#fff',
                symbolSize: 10,
                symbol: 'circle',
                data: finalData1?.map(itr =>
                    itr?.TARGET_TOTAL
                ),
                lineStyle: {
                    width: 3,
                },
            },
        ]
    } else if (performance == "student_special_ability") {
        data = [
            {
                name: 'Yes',
                type: 'bar',
                stack: 'Yes',
                barWidth: "10%",
                color: '#2b79b3',
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_1
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'No',
                type: 'bar',
                stack: 'No',
                barWidth: "10%",
                color: '#2b3d82',
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_2
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'Actual',
                type: 'line',
                stack: 'Actual',
                color: '#fff',
                symbolSize: 10,
                symbol: 'circle',
                data: finalData1?.map(itr =>
                    itr?.TARGET_TOTAL
                ),
                lineStyle: {
                    width: 3,
                },
            },
        ]
    } else if (performance == "student_grade") {
        data = [
            {
                name: 'Preschool/Prekindergarten',
                type: 'bar',
                stack: 'Preschool/Prekindergarten',
                barWidth: "10%",
                color: '#2b79b3',
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_1
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'KinderGarten',
                type: 'bar',
                stack: 'KinderGarten',
                barWidth: "10%",
                color: '#2b3d82',
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_2
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'First Grade',
                type: 'bar',
                stack: 'First Grade',
                barWidth: "10%",
                color: '#802a64',
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_3
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'Second Grade',
                type: 'bar',
                stack: 'Second Grade',
                barWidth: "10%",
                color: '#13917c',
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_4
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'Third Grade',
                type: 'bar',
                stack: 'Third Grade',
                barWidth: "10%",
                color: '#cac2f9',
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_5
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'Fourth Grade',
                type: 'bar',
                stack: 'Fourth Grade',
                barWidth: "10%",
                color: '#2b79b3',
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_6
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'Fifth Grade',
                type: 'bar',
                stack: 'Fifth Grade',
                barWidth: "10%",
                color: '#2b79b3',
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_7
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'Sixth Grade',
                type: 'bar',
                stack: 'Sixth Grade',
                barWidth: "10%",
                color: '#2b79b3',
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_8
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'Seventh Grade',
                type: 'bar',
                stack: 'Seventh Grade',
                barWidth: "10%",
                color: '#2b3d82',
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_9
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'Eighth Grade',
                type: 'bar',
                stack: 'Eighth Grade',
                barWidth: "10%",
                color: '#802a64',
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_10
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'Ninth Grade',
                type: 'bar',
                stack: 'Ninth Grade',
                barWidth: "10%",
                color: '#13917c',
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_11
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'Tenth Grade',
                type: 'bar',
                stack: 'Tenth Grade',
                barWidth: "10%",
                color: '#cac2f9',
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_12
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'Eleventh Grade',
                type: 'bar',
                stack: 'Eleventh Grade',
                barWidth: "10%",
                color: '#2b79b3',
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_13
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'Twelfth Grade',
                type: 'bar',
                stack: 'Twelfth Grade',
                barWidth: "10%",
                color: '#2b3d82',
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_14
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'Early Education',
                type: 'bar',
                stack: 'Early Education',
                barWidth: "10%",
                color: '#802a64',
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_15
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'Actual',
                type: 'line',
                stack: 'Actual',
                color: '#fff',
                symbolSize: 10,
                symbol: 'circle',
                data: finalData1?.map(itr =>
                    itr?.TARGET_TOTAL
                ),
                lineStyle: {
                    width: 3,
                },
            },
        ]
    }

    const productTemplate = (student_performance_tile_reportdatanew) => {
        return (
            <Top_tile_report clicker={student_performance_tile_reportdatanew} clickerFunc={handleTopTileFilterClick} METRIC_GROUP={student_performance_tile_reportdatanew?.METRIC_GROUP} METRIC_ID={student_performance_tile_reportdatanew?.METRIC_ID} METRIC_NAME={student_performance_tile_reportdatanew?.METRIC_NAME}
                CURRENT_PERIOD={student_performance_tile_reportdatanew?.CURRENT_PERIOD} TARGET={student_performance_tile_reportdatanew?.TARGET}
                TARGET_VARIANCE={student_performance_tile_reportdatanew?.TARGET_VARIANCE} LAST_PERIOD={student_performance_tile_reportdatanew?.LAST_PERIOD} LAST_PERIOD_VARIANCE={student_performance_tile_reportdatanew?.LAST_PERIOD_VARIANCE}
            />
        );
    };

    const Top_tile_report = (props) => {
        return (
            <>
                <div onClick={
                    () => props.clickerFunc(props.clicker)
                }>
                    {
                        props?.METRIC_ID == selectedmetric ? <div className='cohortbox-carousel1 pt-4 pb-3'>
                            <div className='product-item'>
                                <div className="heading-text">{props?.METRIC_GROUP != "Enrollment" ? props?.METRIC_GROUP : "Graduation Rate"}</div>
                                <div className='cohortbox2 text-white'>
                                    <div className='slide-header'><p> <i className="pi pi-flag-fill text-[#d9685b]"></i>{props?.METRIC_NAME != "# of Graduates" ? props?.METRIC_NAME : "Graduation Rate"}</p></div>
                                    <div className='grid grid-cols-12'>
                                        <div className='col-span-4'>
                                            <span className="text-xs font-[300] ">Current Period </span>
                                            <p className="text-xl font-[600]">{props?.CURRENT_PERIOD?.toFixed(1)}%</p>
                                        </div>
                                        <div className='col-span-8'>
                                            <div className='flex justify-start items-center'>
                                                <span className="text-xs font-[300]  mx-2">Target </span>
                                                <p className="text-md"> {props?.TARGET?.toFixed(1)}%</p>
                                                {
                                                    props?.TARGET_VARIANCE >= 0 ? <div className='trendingup ml-2' style={{ width: "82px" }}><img src={TreadingarrowUp} className="" />{props?.TARGET_VARIANCE?.toFixed(1)}%</div> :
                                                        <div className='trendingdown ml-2' style={{ width: "82px" }}><img src={TreadingarrowDown} className="" />{props?.TARGET_VARIANCE?.toFixed(1)}%</div>
                                                }
                                            </div>
                                            <div className='flex justify-start items-center'>
                                                <span className="text-xs font-[300]  mx-2">Last Period </span>
                                                <p className="text-md"> {props?.LAST_PERIOD?.toFixed(1)}%</p>
                                                {
                                                    props?.LAST_PERIOD_VARIANCE >= 0 ? <div className='trendingup ml-2' style={{ width: "82px" }}><img src={TreadingarrowUp} className="" />{props?.LAST_PERIOD_VARIANCE?.toFixed(1)}%</div> :
                                                        <div className='trendingdown ml-2' style={{ width: "82px" }}><img src={TreadingarrowDown} className="" />{props?.LAST_PERIOD_VARIANCE?.toFixed(1)}%</div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> :
                            <div className='cohortbox-carousel1 pt-4 pb-3'>
                                <div className='product-item'>
                                    <div className="heading-text">{props?.METRIC_GROUP != "Enrollment" ? props?.METRIC_GROUP : "Graduation Rate"}</div>
                                    <div className='cohortbox1 text-white'>
                                        <div className='slide-header'><p> <i className="pi pi-flag-fill text-[#d9685b]"></i>{props?.METRIC_NAME != "# of Graduates" ? props?.METRIC_NAME : "Graduation Rate"}</p></div>
                                        <div className='grid grid-cols-12'>
                                            <div className='col-span-4'>
                                                <span className="text-xs font-[300] ">Current Period </span>
                                                <p className="text-xl font-[600]">{props?.CURRENT_PERIOD?.toFixed(1)}%</p>
                                            </div>
                                            <div className='col-span-8'>
                                                <div className='flex justify-start items-center'>
                                                    <span className="text-xs font-[300]  mx-2">Target </span>
                                                    <p className="text-md"> {props?.TARGET?.toFixed(1)}%</p>
                                                    {
                                                        props?.TARGET_VARIANCE >= 0 ? <div className='trendingup ml-2' style={{ width: "82px" }}><img src={TreadingarrowUp} className="" />{props?.TARGET_VARIANCE?.toFixed(1)}%</div> :
                                                            <div className='trendingdown ml-2' style={{ width: "82px" }}><img src={TreadingarrowDown} className="" />{props?.TARGET_VARIANCE?.toFixed(1)}%</div>
                                                    }
                                                </div>
                                                <div className='flex justify-start items-center'>
                                                    <span className="text-xs font-[300]  mx-2">Last Period </span>
                                                    <p className="text-md"> {props?.LAST_PERIOD?.toFixed(1)}%</p>
                                                    {
                                                        props?.LAST_PERIOD_VARIANCE >= 0 ? <div className='trendingup ml-2' style={{ width: "82px" }}><img src={TreadingarrowUp} className="" />{props?.LAST_PERIOD_VARIANCE?.toFixed(1)}%</div> :
                                                            <div className='trendingdown ml-2' style={{ width: "82px" }}><img src={TreadingarrowDown} className="" />{props?.LAST_PERIOD_VARIANCE?.toFixed(1)}%</div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }
                </div>

            </>
        );

    };


    // const Top_tile_report = (props) => {  
    //     return (
    //       <>
    //       <div onClick={
    //         () => props.clickerFunc(props.clicker)
    //       }>
    //         {
    //             props?.METRIC_ID == selectedmetric?<div className='cohortbox-carousel1 pt-4 pb-3'>
    //             <div className='product-item'>
    //             <div className="heading-text">Graduates</div>
    //                 <div className='cohortbox2 text-white'>
    //                     <div className='slide-header grid grid-cols-12'>
    //                     <div className='col-span-2'> <p> <i className="pi pi-flag-fill text-[#d9685b]"></i></p></div>
    //                     <div className='col-span-7'> <p>{"Graduation Rate"}</p></div>
    //                     <div className='col-span-3'> <p className="text-xl font-[600]" style={{position:"relative",bottom:"4px",left:"10px"}}>{props?.CURRENT_PERIOD?.toFixed(1)}%</p></div>
    //                     </div>
    //                     <div className='grid grid-cols-12'>
    //                         {/* <div className='col-span-4'>
    //                             <span className="text-xs font-[300] ">Current Period </span>
    //                             <p className="text-xl font-[600]">{props?.CURRENT_PERIOD?.toFixed(1)}%</p>
    //                         </div> */}
    //                         <div className='col-span-12'>
    //                             <div className='flex justify-start items-center'>
    //                                 <span className="text-xs font-[300]  mx-2">Target </span>
    //                                 <p className="text-md"> {props?.TARGET?.toFixed(1)}%</p>
    //                                 {
    //                                     props?.TARGET_VARIANCE >=0? <div className='trendingup ml-2' style = {{width: "55px",fontSize:"11px"}}><img src={TreadingarrowUp} className="" />{props?.TARGET_VARIANCE?.toFixed(1)}%</div>:
    //                                     <div className='trendingdown ml-2' style = {{width: "55px",fontSize:"11px"}}><img src={TreadingarrowDown} className="" />{props?.TARGET_VARIANCE?.toFixed(1)}%</div>
    //                                 }   
    //                             </div>
    //                             <div className='flex justify-start items-center'>
    //                                 <div className="text-xs font-[300]  mx-2">Last Period </div>
    //                                 <p className="text-md"> {props?.LAST_PERIOD?.toFixed(1)}%</p>
    //                                 {
    //                                     props?.LAST_PERIOD_VARIANCE >=0? <div className='trendingup ml-2' style = {{width: "55px",fontSize:"11px"}}><img src={TreadingarrowUp} className="" />{props?.LAST_PERIOD_VARIANCE?.toFixed(1)}%</div>:
    //                                     <div className='trendingdown ml-2' style = {{width: "55px",fontSize:"11px"}}><img src={TreadingarrowDown} className="" />{props?.LAST_PERIOD_VARIANCE?.toFixed(1)}%</div>
    //                                 }   
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             </div>:
    //             <div className='cohortbox-carousel1 pt-4 pb-3'>
    //             <div className='product-item'>
    //                 <div className="heading-text">Graduates</div>
    //                 <div className='cohortbox1 text-white'>
    //                     <div className='slide-header grid grid-cols-12'>
    //                     <div className='col-span-2'> <p> <i className="pi pi-flag-fill text-[#d9685b]"></i></p></div>
    //                     <div className='col-span-7'> <p>{"Graduation Rate"}</p></div>
    //                     <div className='col-span-3'> <p className="text-xl font-[600]" style={{position:"relative",bottom:"4px",left:"10px"}}>{props?.CURRENT_PERIOD?.toFixed(1)}%</p></div>
    //                     </div>
    //                     <div className='grid grid-cols-12'>
    //                         {/* <div className='col-span-4'>
    //                             <span className="text-xs font-[300] ">Current Period </span>
    //                             <p className="text-xl font-[600]">{props?.CURRENT_PERIOD?.toFixed(1)}%</p>
    //                         </div> */}
    //                         <div className='col-span-12'>
    //                             <div className='flex justify-start items-center'>
    //                                 <span className="text-xs font-[300]  mx-2">Target </span>
    //                                 <p className="text-md"> {props?.TARGET?.toFixed(1)}%</p>
    //                                 {
    //                                     props?.TARGET_VARIANCE >=0? <div className='trendingup ml-2' style = {{width: "55px",fontSize:"11px"}}><img src={TreadingarrowUp} className="" />{props?.TARGET_VARIANCE?.toFixed(1)}%</div>:
    //                                     <div className='trendingdown ml-2' style = {{width: "55px",fontSize:"11px"}}><img src={TreadingarrowDown} className="" />{props?.TARGET_VARIANCE?.toFixed(1)}%</div>
    //                                 }  
    //                             </div>
    //                             <div className='flex justify-start items-center'>
    //                                 <span className="text-xs font-[300]  mx-2">Last Period </span>
    //                                 <p className="text-md"> {props?.LAST_PERIOD?.toFixed(1)}%</p>
    //                                 {
    //                                     props?.LAST_PERIOD_VARIANCE >=0? <div className='trendingup ml-2' style = {{width: "55px",fontSize:"11px"}}><img src={TreadingarrowUp} className="" />{props?.LAST_PERIOD_VARIANCE?.toFixed(1)}%</div>:
    //                                     <div className='trendingdown ml-2' style = {{width: "55px",fontSize:"11px"}}><img src={TreadingarrowDown} className="" />{props?.LAST_PERIOD_VARIANCE?.toFixed(1)}%</div>
    //                                 }   
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             </div>
    //         }
    //         </div>

    //       </>
    //     );

    // };


    return (
        <div className="pb-10 body">
            <TopNav pagename="Student Performance & Progress" pagenamedescription="Key Facts about the District" setUserData={props.setUserData} />
            <div className="carousel-holder1">
                <Carousel value={student_performance_tile_reportdatanew} numScroll={1} numVisible={5} responsiveOptions={responsiveOptions} itemTemplate={productTemplate} />
            </div>
            <div className="card wrapper-card text-white mx-5 mt-5 mb-1">
                <div className="flex justify-between">
                    <div style={{ position: "relative", top: "24px" }}>
                        <div className="title-text text-xl flex items-center">Student's Dimension Analysis</div>
                        {/* <div className="text-xs flex items-center">Selected Indicator: {selectedmetricname}</div> */}
                    </div>
                    <div className="flex justify-center" >
                        <div className="mx-1 enrollselect">
                            <label htmlFor="dd-city" className="labeltext">Analyze by</label>
                            <Dropdown value={performance} onChange={(e) => setPerformance(e.value)} options={options} optionLabel="name"
                                placeholder="Ethnicity" className="w-full md:w-14rem" style={{ width: 250 }} />
                        </div>
                    </div>
                </div>
            </div>

            <div className='grid grid-cols-12 mx-5 mt-2 gap-3'>
                <div className='col-span-8'>
                    <div className='echart h-full relative wrap-component'>
                        <div className="title-text flex items-center text-white ml-5 py-5">Time Trending View - {selectedmetricname}</div>
                        <div className="card timetreanding-carousel px-4 mb-4">
                            {
                                finalData && <Carousel value={finalData} numScroll={1} numVisible={5} indicators={false} responsiveOptions={responsiveOptions} itemTemplate={productTemplates} />
                            }
                        </div>
                        {/* {
                            performance == "student_ethnicity" ? <ReactEcharts option={CollectionTrend} />: performance == "student_gender"? <ReactEcharts option={CollectionTrendgender}/>:null
                        } */}
                        {/* <ReactEcharts option={CollectionTrend} />  */}
                        <StachedBarChart data={data} finalData1={finalData1} barDimensions={[...new Set(student_dimension_reportdatanew?.map(item => item["DIMENSION"]))]} lineDimensions={["TARGET_TOTAL"]} pivotData={combinedData} />
                        {/* {
                            performance == "student_ethnicity" && <StachedBarChart data={data} finalData1={finalData1} barDimensions = {[...new Set(student_dimension_reportdatanew?.map(item=> item["DIMENSION"]))]} lineDimensions={["TARGET_TOTAL"]} pivotData={combinedData} />
                        }
                        {
                            performance == "student_gender" && <StachedBarChart data={data} finalData1={finalData1} />
                        }
                        {
                            performance == "student_homeless" && <StachedBarChart data={data} finalData1={finalData1} />
                        }
                        {
                            performance == "student_economically_disadvantaged" && <StachedBarChart data={data} finalData1={finalData1} />
                        }
                        {
                            performance == "student_ell" && <StachedBarChart data={data} finalData1={finalData1} />
                        }
                        {
                            performance == "student_special_ability" && <StachedBarChart data={data} finalData1={finalData1} />
                        }
                        {
                            performance == "student_grade" && <StachedBarChart data={data} finalData1={finalData1} />
                        } */}


                    </div>
                </div>
                <div className="card col-span-4 wrap-component">
                    <div className="title-text flex items-center text-white ml-2 py-5">Performance Summary View</div>
                    <div className="px-2">
                        <div className='graduationtable relative'>
                            <div className='flex justify-around items-center '>
                                <div className='text-[#FFFFFF] text-[14px]'> </div>
                                <div className='text-[#FFFFFF] text-[14px]'> </div>
                                <div className='text-[#FFFFFF] text-[14px]'> </div>
                            </div>
                            <div className='collegejoindata'>
                                <table class='table-auto border-collapse'>
                                    <thead className='bg-[#081631] h-[40px] border-t-2 border-white'>
                                        <tr>
                                            <th><Link>Student Group</Link></th>
                                            <th><Link>District Performance</Link></th>
                                            <th><Link>Target</Link></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentItems && currentItems?.map(itr => {
                                            return (
                                                <tr className='bg-[#1a253b]'>
                                                    <td><p>{itr?.DIMENSION}</p></td>
                                                    <td><p>{itr?.GROUPBY_DISTRICT_PERFORMANCE?.toFixed(1)}%</p></td>
                                                    <td><p>{itr?.GROUPBY_TARGET}%</p></td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                <div className='flex justify-around items-center bg-[#1d2634] h-[40px] border-t-4 border-white'>
                                    <button className={`paginationprevious ${currentPage === 1 ? 'disabled' : ''}`} style={{ fontSize: "12px", color: "#FFFFF" }} onClick={handlePrevClick} disabled={currentPage === 1}>Previous</button>
                                    <button className={`paginationnext ${currentPage === totalPages ? 'disabled' : ''}`} style={{ fontSize: "12px", color: "#FFFFF" }} onClick={handleNextClick} disabled={currentPage === totalPages}>Next</button>
                                    {/* text-[#898d95] text-[12px] */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-end pt-4">
                <img src={k12darklogo} height="50px" width="130px" alt="logo" />
            </div>
        </div>
    )
}